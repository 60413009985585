/*
==================================================================
Fichier d'aide pour le calcule des crédits / créances / consommation des fiches membres pour le server studioconnect V2
==================================================================
api.studioconnect.fr

Fichier modifiable et adaptable.
==================================================================
26/04/2021
==================================================================




Récupérer les crédits + crédits groupe ( donne une liste d'objets avec les crédits par groupes )
---------------------------------------------------------------
let rep = [File JS].GetCredit( list_booking, list_item )
console.log( rep.credit )
---------------------------------------------------------------


Récupérer les créances:
---------------------------------------------------------------
let creance = [File JS].BuildCreance( list_invoice );
---------------------------------------------------------------


Récupérer la consommation:
---------------------------------------------------------------
let conso = [File JS].BuildConsumption( list_item );
---------------------------------------------------------------


======================================================================
Récupérer les listes de data depuis le serveur: "api.studioconnect.fr":

---------------------------------------------------------------
>> list_booking :
api.studioconnect.fr/[UID STUDIO]/user/booking

---------------------------------------------------------------
>> list_item :
api.studioconnect.fr/[UID STUDIO]/user/item

---------------------------------------------------------------
>> list_invoice :
api.studioconnect.fr/[UID STUDIO]/user/caddy
*/


export default
{

  
    //======================================================================================================
    // 
    //======================================================================================================
    roundDecimal(nombre, precision)
    {
        var precision = precision || 2;
        var tmp = Math.pow(10, precision);
        return Math.round( nombre*tmp )/tmp;
    },


    
    //======================================================================================================
    //
    //======================================================================================================
    getDateFin( expire, date )
    {
      if(date == null )
        return new Date();
      if(expire == 0 )
        return new Date();
  
      //fin abo
      let d = new Date( date );
      d = d.addDays( expire );
      d.setHours(23);
      d.setMinutes(59);
      d.setSeconds(59);
        
      return d;
    },
  

    
    //======================================================================================================
    //
    //======================================================================================================
    getDateDebut( date )
    {
      if(date == null )
        return new Date();
  
      //fin abo
      let d = new Date( date );
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
        
      return d;
    },



    //======================================================================================================
    // private data machine
    //======================================================================================================
    private_machine_list : [],


    //======================================================================================================
    // Machine filtre + save
    //======================================================================================================
    getNameMachineAdd( machine, mtype )
    {
      if( this.private_machine_list[ machine ] == undefined )
      this.private_machine_list[ machine ] = mtype;
      return this.getNameMachine( machine );
    },



    //======================================================================================================
    // Machine filtre
    //======================================================================================================
    getNameMachine( machine )
    {
      if(!machine)
        return 'global';
      
      if( this.private_machine_list[ machine ] == undefined )
        return machine;

      if (this.private_machine_list[ machine ] == 'm')
        return 'global';

      return machine;
    },



    //======================================================================================================
    // Parsing item fa/av/re
    //======================================================================================================
    getItemParse( tab_item )
    {
        let item_rep = [];
        //Parse ITEM
        for( var i=0; i<tab_item.length; i++)
        {
            let data = tab_item[i];

            let expire = data.expire;
            let pttc = data.prix*(1+(data.tva/100));
            if( data.type=="av")
            {
                pttc   = (data.prix*-1)*(1+((data.tva*-1)/100))*-1;
                expire = 0;//Pas d'expiration pour les avoirs
            }
            pttc = this.roundDecimal( pttc )

            item_rep.push({
                uid   : data.uid,
                name  : data.name,
                type  : data.type,
                caddy : data.caddy,
                ttc   : pttc,
                expire: expire,
                credit: data.credit,
                groupe: data.machine,
                date  : data.createAt,
                favoir: null,
                fname : null,
            });
        }


        //triage date
        item_rep.sort(function(a, b)//trier valeur
        {
            let a_date = (new Date(a.date)).getTime();
            let b_date = (new Date(b.date)).getTime();

                if(a_date> b_date) 
                return -1;
                if(b_date> a_date) 
                return 1;
        });

        //relation facture / avoir
        for(var i=0; i<item_rep.length;i++)
        {
            var buffer = item_rep[i];
            var ok = true;
            if( buffer.type=="av")
            for(var p=i; p<item_rep.length; p++)
            {
                var bverif = item_rep[p];
                if(ok)
                if(!bverif.favoir)//eviter une double affectation
                if(bverif.type=="fa")
                if(bverif.name==buffer.name)
                if(bverif.ttc==(buffer.ttc*-1))//Attention TTC Negatif pour l'avoir !
                {
                    ok = false;
                    bverif.favoir = buffer.caddy;
                    
                    buffer.favoir  = bverif.caddy;
                }
            }
        }

        //final return
        return item_rep;
    },



    //======================================================================================================
    // Relation item<=>booking
    //======================================================================================================
    fget( booking_list, tab_item)
    {
        var item_filtre = [];

        //Relation avoir/crédit
        let item_list = this.getItemParse( tab_item );

        //-------------------------------------------------------
        //Eliminer avoir et credit = 0
        for( var i=0; i<item_list.length; i++ )
        if(item_list[i].credit!=0 || item_list[i].credit==null)//Si crédit ou si infinie
        if(item_list[i].type != 'av' )
          item_filtre.push( item_list[i] );

        item_filtre = item_filtre.reverse();

        //-------------------------------------------------------
        //Triage booking
        booking_list.sort(function(a, b)
        {
            let a_date = (new Date(a.date)).getTime();
            let b_date = (new Date(b.date)).getTime();

                if(a_date> b_date) 
                return 1;
                if(b_date> a_date) 
                return -1;
        });
        
        //-------------------------------------------------------
        //Reservation concervé + triage par groupe
        var booking_tab_filtre = {};
        for( var c=0; c<booking_list.length; c++)
        {
          if( booking_list[c].type !='e')//Enlever essai 
          //if( booking_list[c].type !='c')//et contractuel
          if( booking_list[c].credit != 0 )//Enlever seance zero crédit
          {
            let buffer = booking_list[c];

            var machine = this.getNameMachineAdd(buffer.machine, buffer.mtype);

            if(!booking_tab_filtre[machine])
              booking_tab_filtre[machine] = [];
            booking_tab_filtre[machine].push( buffer );
          }
        }
        //-------------------------------------------------------
        //Trier les items par groupe
        var booking_tab_invoice = {};
        for( var iv=0; iv<item_filtre.length; iv++)
        {
            let buffer = item_filtre[iv];
            buffer.use = 0;

            var groupe = this.getNameMachine(buffer.groupe);

            if(!booking_tab_invoice[groupe])
            booking_tab_invoice[groupe] = [];
            booking_tab_invoice[groupe].push( buffer );
        }
        
        //-------------------------------------------------------
        //??
        var final_booking_tab = [];
        //boucle par type de réservation
        for( const filtre_name in booking_tab_filtre )
        {
          var buffer_booking = booking_tab_filtre[filtre_name];
          //console.log( booking_tab_invoice[filtre_name] )

          //verifier que le filtre existe dans facture
          if(booking_tab_invoice[filtre_name])
          {
            //----------------------------------------------
            //Traiter les booking
            for( var b=0; b<buffer_booking.length; b++ )
            if( buffer_booking[b].type !="m")//Uniquement sur les studios
            {

                //----------------------------------------------
                //Get booking used
                var booking_buffer = buffer_booking[b];
                var Bend_date = (new Date(booking_buffer.date)).getTime();
                var facture_select = null;

                //----------------------------------------------
                //Calculer facture a utilisé
                let ok = false;
                let compte = 0;
                let lock = true;
                while( facture_select==null || booking_tab_invoice[filtre_name][compte]!=undefined )
                {
                  let buffer = booking_tab_invoice[filtre_name][compte];
                  if(buffer == null )
                    break;
                  
                  //console.log( buffer );
                  if( buffer.use < buffer.credit )
                  {
                      //console.log( "oki: "+buffer.use+" < "+buffer.credit+" => "+buffer.expire)
                      //si pas expire
                      if(buffer.expire > 0)
                      {
                        var Iend_date   = this.getDateFin(buffer.expire, buffer.date).getTime();
                        var Istart_date = this.getDateDebut( buffer.date ).getTime();

                        if( Bend_date < Iend_date)//si séance au dela de l'expiration on va à la suivante
                        if( Bend_date > Istart_date )
                        {
                          facture_select = buffer;
                          break;
                        }
                      }
                      else
                      {
                        if(lock==true)
                        {
                          lock = false;
                          facture_select = buffer;
                        }
                      }
                  }
                  compte++;
                }

                //Set facture select
                if( facture_select != null )
                {
                  facture_select.use += buffer_booking[b].credit;
                }

                booking_buffer.item = facture_select;
                final_booking_tab.push(booking_buffer);
            }
          }
          else
          //Aucune facture existe pour ce filtre
          {
            for(var p=0; p<buffer_booking.length;p++)
            {
              var buf = buffer_booking[p];
              buf.item = null;
              final_booking_tab.push(buf);
            }
          }
        }

        //---------------------------------------------
        //Triage final des item
        var item_seance = {};
        for( var k=0; k<final_booking_tab.length;k++)
        {
            var buid = 'global';
            if( final_booking_tab[k].item)
            buid = final_booking_tab[k].item.uid;

            if(!item_seance[buid])
            item_seance[buid] = 0;
            item_seance[buid] += final_booking_tab[k].credit;
        }
        
        //---------------------------------------------
        //Final return data
        return {
                credit  : item_seance,       //item used avec credit
                booking : final_booking_tab, //booking avec item associé
                item    : item_list          //Item parsing
        };
    },


    //======================================================================================================
    // Récupérer les relations item/booking et crédits
    //======================================================================================================
    GetCredit( booking_list, tab_item )
    {
        //Get crédit + item parse + relation
        let fdata = this.fget( booking_list, tab_item );

        let item_credit_use = fdata.credit;
        let list_item_used  = fdata.item;
        
        //console.log( fdata );

        //---------------------------------------------
        //Compacter les données
        var tab_final = [];
        for( var i=0; i<list_item_used.length; i++ )
        if( list_item_used[i].credit!=0)
        {
            var buffer = list_item_used[i];
            buffer.used = 0;
            if(item_credit_use[ buffer.uid ])
                buffer.used = item_credit_use[ buffer.uid ];
            tab_final.push(buffer);
        }

        //---------------------------------------------
        //add special (si aucune facture)
        if( item_credit_use['global'] )
            tab_final.push({
                credit: 0,
                used  : item_credit_use['global'],
                date  : null,
                groupe: null,
            });

        //---------------------------------------------
        //Tableau des crédit
        var date_actu = (new Date()).getTime();
        var list_credit = {};
        for( var p=0; p<tab_final.length; p++)
        {
            buffer = tab_final[p];
            var credit_add = buffer.credit;
            var groupe = 'global';

            //Expiration
            var date_ext = (new Date(this.getDateFin( buffer.expire, buffer.date ))).getTime();
            if( date_actu > date_ext )
                credit_add = buffer.used;
            
            //groupe
            if(buffer.groupe)
                groupe = buffer.groupe;

            if(!list_credit[groupe])
                list_credit[groupe] = {
                    /*groupe:groupe,*/ //Si rajouter nom du groupe directement dans l'objet
                    credit:0,
                    used:0
                };
            list_credit[groupe].credit += credit_add-buffer.used;
            list_credit[groupe].used   += buffer.used;
        }
        
        //---------------------------------------------
        //final return
        return {
            credit  : list_credit,  //Etat des crédits
            item    : fdata.item,   //Utilisation des crédits items avec relation avoir/recu
            booking : fdata.booking //Relation reservation / item vendu
        };
    },


    //======================================================================================================
    // Fabriquer le total créance
    //======================================================================================================
    BuildCreance( list_facture )
    {
      let creance = 0;
      for( var i=0; i<list_facture.length; i++)
      {
        switch(list_facture[i].type)
        {
            case 'fa':
            case 'av':
              creance += parseFloat(list_facture[i].pay_dif) + parseFloat(list_facture[i].pay_prele );
            break;
            case 're':
              creance += parseFloat(list_facture[i].pay_dif);
            break;
        }
      }
      return creance;
    },



    //======================================================================================================
    // Fabriquer le total consommation
    //======================================================================================================
    BuildConsumption( list_item )
    {
      let list = this.getItemParse( list_item );

      let ttc = 0;
      for( var i=0; i<list.length; i++)
        ttc += list[i].ttc;

      return ttc;
    }
}