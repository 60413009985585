<template>
	<div class="vx-row">
		<div class="vx-col w-full sm:w-2/2 lg:w-3/3 mb-base">
			<vx-card>
				<h2><span v-bind:style="getResaColor(booking.type)">{{ StrCapital(getTypeName(booking.type)) }}</span> du {{ getDate(booking.date) }}</h2>
				<vs-divider/>
                <br/>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Membre</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewMember(uid_member)">
						<span>{{member}}</span>
					</div>
				</div>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Effectuez sur</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewMachine(uid_machine)">
						<span>{{machine}}</span>
					</div>
				</div>

				<!--<div class="vx-row mb-6">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Vendeur</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewMember(uid_vendor)">
						<span>{{vendor}}</span>
					</div>
				</div>-->

				<div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Depuis le terminal</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewTerminal(uid_terminal)">
						<span>{{terminal}}</span>
					</div>
				</div>
                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Facture ref.</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewFacture(uid_facture)">
						<span>{{facture}}</span>
					</div>
				</div>
                <br/>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Venu</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<b>{{ getVenu(booking.venu) }}</b>
					</div>
				</div>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Bilan</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{ getBilan(booking.bilan) }}</span>
					</div>
				</div>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Crédit utilisé</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{booking.credit}}</span>
					</div>
				</div>
                
                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Date enregistrement</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{ getDate(booking.createAt) }}</span>
					</div>
				</div>
            </vx-card>
        </div>
		<vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
				<div>
					<vs-button color="danger" type="filled" icon-pack="feather" v-on:click="removeBooking()">Annuler</vs-button>
					<vs-button color="primary" type="filled" icon-pack="feather" v-on:click="goBooking()" style="margin-left:10px;">Aller au planning</vs-button>
				</div>
            </vs-col>
        </vs-row>
    </div>
</template>



<style lang="scss">
</style>

<script>
import Booking from '@/database/models/booking';
import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';
import Credit from '@/assets/utils/credit';

import Invoice from '@/database/models/invoice';
import Item  from '@/database/models/item';
import Terminal from '@/database/models/terminal';
import Member from '@/database/models/member';
import Machine from '@/database/models/machine';

export default {
    data(){
        return{
            booking:{},
            member    :'?',
            uid_member: null,

            terminal    :'?',
            uid_terminal: null,

            machine     : '?',
            uid_machine : null,

            facture     : 'Aucune',
            uid_facture : null,
        }
	},
	mounted:function()
	{
		loadReservation(this);
    },
    watch:{
        $route (){
            loadReservation(this);
        }
    },
    methods:{
        StrCapital:Utils.StrCapital,
        getTypeName:Booking.getTypeName,
        getDate:function(date)
		{
			if(date)
				return Fdate.getDate( date )+' à '+Fdate.getHeure( date );
			return '?';
        },
        getResaColor:function(type)
        {
            return { 'color':Booking.getColor(type) };
        },
        getVenu:function(venu)
		{
			if(venu)
				return 'Présent';
			return 'Absent';
        },
        getBilan:function(bilan)
        {
            if(bilan)
                return 'Oui';
            return 'Non';
        },
        viewMember:function( uid )
		{
			if(uid)
			this.$router.push('/admin/member/'+uid);
		},
		viewTerminal:function( uid )
		{
			if(uid)
			this.$router.push('/admin/terminal/'+uid);
		},
		viewFacture:function( uid )
		{
			if(uid)
			this.$router.push('/admin/invoice/'+uid);
        },
        viewMachine:function( uid )
		{
			if(uid)
			this.$router.push('/admin/machine/'+uid);
		},
		removeBooking:function()
        {
            let uid = this.booking.uid

            if(!window.confirm("Confirmer suppression ?"))
                return

            if(uid)
            {
                //Manuel suppression
                Booking.keyDelete( uid )
                //Envoi modif serveur
                Booking.remove( uid )
                .then(()=>{
					//redirection membre
					this.$router.push('/admin/member/'+this.uid_member);
                })
                .catch(()=>{
                    alert('Erreur suppression booking')
                })
            }
        },
		goBooking()
		{
			let d = Fdate.getFullDateMin(this.booking.date,false).split('/')

			this.$router.push('/admin/booking/'+d[1]+'-'+d[0]+'-'+d[2]);
		}
    }
};


function loadReservation( root )
{
    let uid_booking = root.$route.params.uid;
    Booking.getMemory( uid_booking,function(data_booking)
    {
        root.booking = data_booking;

        //get member
		if(data_booking.member)
		Member.getMemory( data_booking.member, function(mbr)
		{
			root.member = Utils.StrCapital( mbr.first_name+" "+mbr.last_name );
			root.uid_member = mbr.uid;
        });


        //terminal
        if(data_booking.terminal)
		Terminal.getMemory( data_booking.terminal, function(terminal)
		{
            if(terminal)
            {
                root.terminal = terminal.mac;
                root.uid_terminal = terminal.uid;
            }
        });


        //Machine
        if(data_booking.machine)
		Machine.getMemory( data_booking.machine, function(machine)
		{
            if(machine)
            {
                root.machine     = machine.name;
                root.uid_machine = machine.uid;
            }
        });
        
        //item facture 
        Credit.itemCreditUse( data_booking.member, function( undefined, tab_booking )
        {
            //recherche du booking
            for( var i=0; i<tab_booking.length; i++)
            if( tab_booking[i].uid == data_booking.uid )
            {
                var buffer = tab_booking[i];
                if( buffer.item )
                Item.getMemory( buffer.item, function(item)
                { 
                    if(item)
                    if(item.caddy)
                    Invoice.getMemory( item.caddy,function(invoice)
                    {
                        if(invoice)
                        {
                            root.facture     = invoice.number;
                            root.uid_facture = invoice.uid;
                        }
                    });

                });
            }
        });
        

    });
}

</script>